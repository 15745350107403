import { render, staticRenderFns } from "./template.pug?vue&type=template&id=4478f3bd&scoped=true&lang=pug&external"
import script from "./script.js?vue&type=script&lang=js&external"
export * from "./script.js?vue&type=script&lang=js&external"
import style0 from "./style.sass?vue&type=style&index=0&id=4478f3bd&prod&lang=sass&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4478f3bd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDataTable,VSimpleCheckbox,VTooltip})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
